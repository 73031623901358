import { BASE_API_URL, useMutation, useQuery } from "./util";
import { Auth } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";

export interface Highlight {
  id?: string;
  text: string;
  elemId: string;
  start: number;
  end: number;
  from: string;
}

export const getHighlightsForModule = async (
  moduleSlug: string
): Promise<Highlight[]> => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();

  return fetch(`${BASE_API_URL}/toolbox`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data.Items.filter(
        (item: { type: string; data: { from: string } }) => {
          if (item.type === "highlight") {
            if (item.data?.from === moduleSlug) {
              return true;
            }
          }
        }
      ).map((item: { data: any; itemId: any }) => {
        return { ...item.data, id: item.itemId };
      });
    });
};

export const createHighlight = async (highlight: Highlight): Promise<any> => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken();

  return fetch(`${BASE_API_URL}/toolbox`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      type: "highlight",
      text: highlight.text,
      elemId: highlight.elemId,
      start: highlight.start,
      end: highlight.end,
      from: highlight.from,
    }),
  }).then((res) => res.json());
};

export const deleteHighlight = async (
  highlight: Highlight | null
): Promise<any> => {
  if (highlight === null || highlight.id === null)
    throw new Error("No highlight selected to delete or highlight has no id");

  const token = (await Auth.currentSession()).getIdToken().getJwtToken();

  return fetch(`${BASE_API_URL}/toolbox/${highlight.id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  }).then((res) => res.json());
};

type ToolboxItemData =
  | {
      type: "link";
      data: {
        url: string;
      };
    }
  | {
      type: "highlight";
      data: {
        text: string;
        elemId: string;
        start: number;
        end: number;
        from: string;
      };
    }
  | {
      type: "favourite";
      data: {
        favouriteType: "topic" | "module" | "reflection";
        id: string;
      };
    }
  | {
      type: "resource";
      data: {
        name: string;
        description: string;
        url: string;
        resourceId: string;
      };
    };

export type ToolboxItem = ToolboxItemData & {
  itemId: string;
  id: string;
};

type ToolBoxData = {
  data: ToolboxItem[];
  loading: boolean;
};

type ToolBox = ToolBoxData & {
  fetchData: () => void;
};

export const useToolbox = (): ToolBox => {
  const [state, setState] = useState<any>({
    data: [],
    loading: true,
  });

  const fetchData = useCallback(async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    const response = await fetch(`${BASE_API_URL}/toolbox`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });

    if (!response.ok) throw new Error("Failed to get toolbox");

    const data = await response.json();

    setState({
      data: data.Items ?? [],
      loading: false,
    });
  }, []);

  return { ...state, fetchData };
};

export const useDeleteToolboxItem = () => {
  return useMutation(async (id: string) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    return fetch(`${BASE_API_URL}/toolbox/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    }).then((res) => res.json());
  });
};

export const useAddLinkToToolbox = () => {
  return useMutation(async (url: string) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    return fetch(`${BASE_API_URL}/toolbox`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        type: "link",
        url: url,
      }),
    }).then((res) => res.json());
  });
};

export const useAddFavouriteToolboxItem = () => {
  return useMutation(
    async (
      type: "topic" | "module" | "resource" | "link",
      id: string,
      data?: { name: string; description: string; url: string }
    ) => {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();

      return fetch(`${BASE_API_URL}/toolbox`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(
          type === "resource"
            ? {
                type: "resource",
                url: data ? data.url : "",
                name: data ? data.name : "",
                description: data?.description ?? "",
                resourceId: id,
              }
            : {
                type: "favourite",
                favouriteType: type,
                id,
              }
        ),
      }).then((res) => res.json());
    }
  );
};
