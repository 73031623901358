import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  Center,
  ExpandedIndex,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";
import { navigate } from "gatsby";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import FavouriteButton from "../../components/FavouriteButton";
import {
  ReflectionData,
  REFLECTIONS_FORM_DATA,
} from "../../data/REFLECTIONS_FORM_DATA";
import Layout from "../../components/Layout";
import { useRehydratedForm } from "../../data/formsApi";
import { useModules } from "../../data/modulesAPI";
import { useLocation } from "@reach/router";
import SearchComponent from "../../components/SearchComponent";
import { RepeatClockIcon } from "@chakra-ui/icons";
import InstructionText from "../../components/InstructionText";
import { useToolbox } from "../../data/toolboxApi";

type Props = {};

const Goals = (props: Props) => {
  const modules = useModules();
  const [selectedReflection, setSelectedReflection] =
    useState<ReflectionData>();
  const [index, setIndex] = useState<ExpandedIndex>([]);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { data, fetchData: formRefetch } = useRehydratedForm(selectedReflection?.id);
  const [selectedReflectionDate, setSelectedReflectionDate] =
    useState<string>("0");
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { search } = useLocation();

  const {
    data: toolBoxData,
    loading: toolBoxLoading,
    fetchData: toolBoxRefetch,
  } = useToolbox();

  useEffect(() => {
    toolBoxRefetch();
    formRefetch()
  }, []);

  useEffect(() => {
    if (search) {
      const reflectionId = new URLSearchParams(search).get("id");
      if (reflectionId) {
        const reflection = REFLECTIONS_FORM_DATA.flat().find(
          (r) => r.id === reflectionId
        );
        if (reflection) {
          setSelectedReflection(reflection);
        }
      }
    }
  }, [search]);

  useEffect(() => {
    document.title = "Reflections";
  }, []);

  useEffect(() => {
    if (search && data) {
      const timestamp = new URLSearchParams(search).get("time");
      if (timestamp) {
        const reflection = data.findIndex(
          (d: any) => d.timestamp.toString() === timestamp.toString()
        );
        if (reflection) {
          setSelectedReflectionDate(reflection.toString());
        }
      }
    }
  }, [data]);

  useEffect(() => {
    setSelectedReflectionDate("0");
  }, [selectedReflection]);

  const bg = useColorModeValue("white", "gray.800");
  const blue = useColorModeValue("blue.100", "gray.800");
  const hover = useColorModeValue("grey.100", "gray.700");
  const grey = useColorModeValue("gray.100", "gray.800");
  const greyHover = useColorModeValue("gray.200", "gray.700");

  return (
    <main aria-label="Reflections">
      <Layout>
        <Flex>
          <Center w="full">
            <Heading as="h1">Reflections</Heading>

            <Spacer />
            <InstructionText
              title={"About Reflections"}
              mdId={"instruction-text/reflections"}
              dark={false}
            />
            {isMobile ? <SearchComponent asInput={false} dark={false} /> : null}
          </Center>
        </Flex>
        <Flex w="full" gap={10}>
          {(isMobile && !selectedReflection) || !isMobile ? (
            <Box
              minW={0}
              flex={isMobile ? 1 : 0.4}
              rounded="lg"
              bg={bg}
              boxShadow="lg"
              p={8}
            >
              {/* <Flex>
                <Heading mb={3}>Reflections</Heading>
                <Spacer />
                {isMobile ? (
                  <SearchComponent asInput={false} dark={true} />
                ) : null}
              </Flex> */}

              <Accordion index={index} onChange={setIndex} allowMultiple>
                {modules.map((module, index) => (
                  <AccordionItem
                    border={0}
                    mb={3}
                    isDisabled={REFLECTIONS_FORM_DATA[index].length === 0}
                  >
                    <h2>
                      <AccordionButton
                        bg={bg}
                        border={blue}
                        borderWidth={1}
                        _hover={{ bg: hover }}
                        rounded="md"
                        p={3}
                      >
                        <Box flex="1" textAlign="left">
                          <Text mb={2} fontWeight="bold">
                            {module.title}
                          </Text>
                          <Badge
                            size={"xs"}
                            colorScheme={"green"}
                            p={1}
                            pl={"10px"}
                            pr={"10px"}
                            rounded={"lg"}
                            fontSize="sm"
                          >
                            {REFLECTIONS_FORM_DATA[index].length > 1
                              ? REFLECTIONS_FORM_DATA[index].length +
                                " Reflections"
                              : "1 Reflection"}
                          </Badge>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} px={0}>
                      <Stack>
                        {REFLECTIONS_FORM_DATA[index].map((reflection) => (
                          <Button
                            rounded="md"
                            variant={"outline"}
                            p={2}
                            textAlign="center"
                            fontWeight="bold"
                            onClick={() => setSelectedReflection(reflection)}
                            style={{
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                            }}
                            _hover={{
                              cursor: "pointer",
                              bg: greyHover,
                            }}
                          >
                            {reflection.name}
                          </Button>
                        ))}
                      </Stack>
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </Box>
          ) : null}
          {(isMobile && selectedReflection) || !isMobile ? (
            <Box
              flex={1}
              maxW="100%"
              minW={0}
              rounded="lg"
              bg={bg}
              boxShadow="lg"
              p={8}
            >
              {selectedReflection ? (
                <Box>
                  <Flex alignItems="center" justify="space-between" mb={8}>
                    <Flex gap={3} alignItems="center">
                      <IconButton
                        onClick={() => setSelectedReflection(undefined)}
                        aria-label="back"
                        icon={<FaChevronLeft />}
                        mr={3}
                      />
                      <Heading size="lg">{selectedReflection.name}</Heading>
                    </Flex>

                    <Wrap justify="right">
                      {selectedReflection.id ? (
                        <FavouriteButton
                          favouriteType="reflection"
                          id={selectedReflection.id}
                          toolBoxLoading={toolBoxLoading}
                          toolBoxData={toolBoxData}
                          refetchToolbox={toolBoxRefetch}
                        />
                      ) : null}

                      {selectedReflection.id ? (
                        <IconButton
                          onClick={() => {
                            onOpen();
                          }}
                          aria-label="See historical reflections"
                          icon={<RepeatClockIcon />}
                        />
                      ) : null}

                      {selectedReflection.link ? (
                        <Button
                          onClick={() => {
                            // @ts-ignore
                            navigate(selectedReflection.link);
                          }}
                          aria-label="Go to reflection module"
                        >
                          Go to module
                        </Button>
                      ) : null}
                    </Wrap>
                  </Flex>
                  <Box>
                    {selectedReflectionDate !== "0" ? (
                      <Alert status="info" mb={5}>
                        <AlertIcon />
                        This is a historical answer. To view the latest answer{" "}
                        <Button
                          ml={1}
                          variant="link"
                          onClick={() => setSelectedReflectionDate("0")}
                        >
                          click here
                        </Button>
                      </Alert>
                    ) : null}
                    {/* @ts-ignore */}
                    <selectedReflection.component
                      date={selectedReflectionDate}
                    />
                  </Box>
                </Box>
              ) : (
                <Flex minW="0" h="full" justify="center" alignItems="center">
                  <Text opacity={0.8} maxW={400} textAlign="center">
                    Select a reflection to view it here
                  </Text>
                </Flex>
              )}
            </Box>
          ) : null}
        </Flex>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>Select Date</ModalHeader>
            <ModalBody>
              <Select
                value={selectedReflectionDate}
                onChange={(e) => {
                  setSelectedReflectionDate(e.target.value);
                  onClose();
                }}
              >
                {data?.map((reflectionData: any, i: number) => (
                  <option value={i}>
                    {i === 0
                      ? "Latest Answer"
                      : `${moment(
                          reflectionData.timestamp
                        ).fromNow()} (${moment(reflectionData.timestamp).format(
                          "DD/MM/YYYY HH:mm"
                        )})`}
                  </option>
                ))}
              </Select>
            </ModalBody>
            <ModalFooter>
              <Button mr={3} onClick={onClose}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Layout>
    </main>
  );
};

export default Goals;
