import { Icon, IconButton, useToast } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import {
  ToolboxItem,
  useAddFavouriteToolboxItem,
  useDeleteToolboxItem,
  useToolbox,
} from "../data/toolboxApi";

type Props = {
  favouriteType: "module" | "topic" | "reflection" | "resource";
  id: string | number;
  moreData?: { name: string; description: string; url: string };
  toolBoxData: ToolboxItem[] | null;
  refetchToolbox: Function;
  toolBoxLoading: boolean;
};

const FavouriteButton = ({
  favouriteType,
  id,
  moreData,
  toolBoxLoading,
  toolBoxData,
  refetchToolbox,
}: Props) => {
  // const {
  //   data: fetchedData,
  //   isLoading,
  //   refetch: localRefetch,
  // } = useToolbox({ enabled: toolboxData === undefined });

  // const { data: fetchedToolboxData, loading:  fetchData } = useToolbox();

  const addFavourite = useAddFavouriteToolboxItem();
  const removeFavourite = useDeleteToolboxItem();

  const toast = useToast();

  // const data = useMemo(() => {
  //   if (toolboxData) return toolboxData;
  //   return fetchedData;
  // }, [fetchedData, toolboxData]);

  // const refetch = useMemo(() => {
  //   if (refetchToolbox) return refetchToolbox;
  //   return localRefetch;
  // }, [refetchToolbox, localRefetch]);

  const favourited = useMemo(() => {
    return toolBoxData?.find(
      (
        item: any //TODO: Type this properly
      ) =>
        favouriteType !== "resource"
          ? item.type === "favourite" &&
            item.data.favouriteType === favouriteType &&
            item.data.id === id.toString()
          : item.type === "resource" && item.data.resourceId === id.toString()
    );
  }, [toolBoxData, favouriteType, id]);

  const toggleFavourite = useCallback(
    async (e: any) => {
      e.stopPropagation();

      if (favourited) {
        // remove
        const res = await removeFavourite.mutateAsync(favourited.itemId);
        if (!res.isError) {
          toast({
            title: "Removed favourite",
            status: "info",
            duration: 1500,
            isClosable: true,
            position: "top",
          });
        }
      } else {
        //@ts-ignore
        const res = await addFavourite.mutateAsync(
          favouriteType,
          id.toString(),
          moreData
        );
        if (!res.isError) {
          toast({
            title: "Added to favourites",
            description: "You can find this in your toolbox",
            status: "info",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        }
      }
      refetchToolbox();
    },
    [favourited, refetchToolbox]
  );

  return (
    <IconButton
      aria-label="Favourite Button"
      isLoading={
        toolBoxLoading || addFavourite.isLoading || removeFavourite.isLoading
      }
      icon={<Icon as={!!favourited ? FaHeart : FaRegHeart} />}
      onClick={toggleFavourite}
    ></IconButton>
  );
};

export default FavouriteButton;
